<template>
  <erp-s-field
      view="lr"
      label="Sigla:"
  >
    <erp-input
        simple-border
        v-model="inptVal"
        :loading="isVerificandoSigla"
        class="text-positive"
    >
    </erp-input>
  </erp-s-field>
</template>

<script>
import {validaSiglaPatio} from "@/domain/patio/services";
import {
  ErpSField,
  ErpInput
} from 'uloc-vue-plugin-erp'

export default {
  name: "SiglaPatioInput",
  props: {
    local: {
      type: Boolean,
      default: () => false
    },
    value: {
      type: String,
      default: () => ''
    }
  },
  components: {
    ErpInput,
    ErpSField
  },
  data () {
    return {
      isVerificandoSigla: false
    }
  },
  computed: {
    inptVal: {
      get () {
        return this.value
      },
      set (v) {
        this.$emit('input', v)
      }
    }
  },
  methods: {
    validaSigla (evt) {
      const sigla = evt.target.value?.trim()
      if (!sigla) return
      this.isVerificandoSigla = true
      validaSiglaPatio(sigla, this.local)
          .then(result => {
            console.log(result)
          })
          .catch(error => {
            this.alertApiError(error)
          })
          .finally(() => {
            this.isVerificandoSigla = false
          })
    }
  }
}
</script>
